import request from "../composables/useHttp";

/**
 * @description: 获取专题 banner
 * @param: {path}： 专题路径
 * */
export function getTopicBanner(path) {
    return request("/special-topic/gw/findGWSpecialTopicBanner", {
        method: 'get',
        params: {path}
    })
}