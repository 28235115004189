<template>
  <swiper :navigation="false" :modules="modules" :autoplay='{delay: 3000, disableOnInteraction: false}' :loop="true"
          class="bannerSwiper" :style="{height: swiperHeight}" effect="fade" id="swiperEl" :pagination="{dynamicBullets:true, clickable:true}">
    <swiper-slide v-for="(item, index) in bannerArr" :key="index">
      <a :href="item.bannerContentUrl || 'javascript:;'" :target="item.bannerContentUrl ? '_blank' : '_self'">
        <img :src="item.bannerImageUrl" alt="banner">
      </a>
    </swiper-slide>
  </swiper>
</template>

<script setup>
// 轮播图初始高度
import {ref} from "vue";
import {Autoplay, Navigation, EffectFade, Pagination} from "swiper";
const modules = ref([Navigation, Autoplay, EffectFade, Pagination])
// dynamicBullets
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import 'swiper/css/effect-fade';
import 'swiper/css/bundle'
import {getBanner} from "../apis";
import { getTopicBanner } from "../apis/topic";

const props = defineProps({
  pageNumber: {
    required: true,
    type: [Number,String]
  },
  pageName: {
    default: 'other'
  }
})

const swiperHeight = ref('0')
/**
 * @description: 在客户端环境下，设置轮播区域及图片大小为屏幕宽度的1/2
 * */
function getSwiperHeight() {
  if (!process.server) {
    // console.log(document.getElementById('swiperEl').classList)
    let screenWidth = document.body.clientWidth;
    // swiperHeight.value = (screenWidth / 7.46) + 'px'
    // 保持banner图部分图片比例; 首页：1/2， 其他：2/5
    if (props.pageName === 'home') {
      // swiperHeight.value = (screenWidth / 2) + 'px'    // 原始
      swiperHeight.value = (screenWidth / 2.5) + 'px'   // 第二次改
    }
    // else if (props.pageName === 'rlzy') {
    //   // swiperHeight.value = '266px'   // 设计图1920下高度
    //   swiperHeight.value = (screenWidth / 7.2) + 'px'
    // }
    else if(props.pageName === 'fixed') {
      swiperHeight.value = '325px'
      setTimeout(() => {
        document.getElementById('swiperEl').classList.remove("bannerSwiper")
        document.getElementById('swiperEl').classList.add("fixedSwiper")
      })
    }
    else {
      swiperHeight.value = '0'
      // swiperHeight.value = (screenWidth / 7.46) + 'px' // 最新要改的

      // swiperHeight.value = ((screenWidth / 5) * 2) + 'px'    // 原始
    }
  }
}

// 在页面初始化时触发设置轮播图大小方法
onMounted(() => {
  // 监听窗口变化，实时设置轮播大小
  window.addEventListener('resize',getSwiperHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', getSwiperHeight)
})

/**
 * @description: 获取banner图数据，并补全路径，在服务端 - 初次调用
 * */
const bannerArr = ref([]);

if (props.pageName === 'zhuanti') {
  const topicBannerRes = await useAsyncData('combinationBanner', () => getTopicBanner(props.pageNumber))
  if (topicBannerRes.data.value.code !== 200) {
    bannerArr.value = []
    throw createError({statusCode: topicBannerRes.data.value.code, statusMessage: encodeURIComponent('获取专题banner失败')})
  } else {
    let bannerData = topicBannerRes.data.value.data;
    if (bannerData && bannerData.length) {
      let _obj = {
        bannerContentUrl: null,
        bannerImageUrl: import.meta.env.VITE_FILE_HOST + bannerData
      }
      bannerArr.value.push(_obj)
    }
  }
} else {
  const bannerArrRes = await useAsyncData('combinationBanner', () => getBanner(props.pageNumber))
  if (bannerArrRes.data.value.code !== 200) {
    bannerArr.value = []
    throw createError({statusCode: bannerArrRes.data.value.code, statusMessage: encodeURIComponent('获取banner失败')})
  } else {
    let bannerData = bannerArrRes.data.value.data;
    if (bannerData && bannerData.length) {
      bannerData.forEach(item => {
        let _obj = {
          bannerContentUrl: item.bannerContentUrl,
          bannerImageUrl: import.meta.env.VITE_FILE_HOST + item.bannerImageUrl
        }
        bannerArr.value.push(_obj)
      })
    }
  }
}
getSwiperHeight()
</script>


<style scoped lang="scss">
.bannerSwiper {
  width: 100%;
  overflow: hidden;
  .swiper-slide {
    width: 100%;
    height: 100%;
    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      > img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }
}

$fixedSwiperWidth: 1565px;

.fixedSwiper{
    width: $fixedSwiperWidth;
    height: 325px !important;
    overflow: hidden;
  //transition: .3s all ease-in-out;
  .swiper-slide {
    width: 100%;
    height: 100%;
    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      > img {
        width: $fixedSwiperWidth;
        height: 100%;
        object-fit:cover;
      }
    }
  }
}

@media (max-width: 1600px) {
    $fixedSwiperWidth: 1300px;
    .fixedSwiper{
        width: $fixedSwiperWidth;
        height: 270px !important;
        .swiper-slide {
            > a {
                > img {
                    width: $fixedSwiperWidth;
                }
            }
        }
    }
}
</style>
